import Image from "next/image";
import Link from "next/link";
import { ImWhatsapp } from "react-icons/im";

const ContactSection = () => {
  return (
    <div className="bg-teal-600 text-white text-center p-10">
      <h3 className="font-bold text-white">LET’S TALK</h3>
      <p className="mt-4">Enough about us, we want to hear your story.</p>
      <div className="flex flex-col items-center mt-8">
        <div className="">
          <Image
            src="/images/sumeet-shroff-profile-pic.jpg"
            alt="Sumeet Shroff"
            className="rounded-full"
            width={100}
            height={50}
          />
        </div>
        <p className="mt-2">Sumeet Shroff</p>
        <Link
          target="blank"
          className="self-center flex"
          href="https://wa.me/919821212676"
          passHref
        >
          <ImWhatsapp size={50} className="text-green-400 p-3 self-center" />
          <p className="mt-4 text-2xl lg:text-4xl font-bold tracking-widest">
            +91 98212 12676
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ContactSection;
