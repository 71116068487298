"use client";
import React from "react";
import SubscribeForm from "@/components/SubscribeForm";
import ContactSection from "@/components/ContactSection";
import Link from "next/link";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaLinkedin,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaGithub,
} from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <SubscribeForm />
      <ContactSection />
      <footer className="bg-slate-900 text-slate-200 flex flex-col relative">
        <section className="py-6 md:py-8 lg:py-10 flex-1 bg-color-brown-900">
          <div className="max-w-screen-xl mx-5 lg:mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 md:gap-8 items-start justify-between text-sky-400">
              <div className="lg:col-span-1 space-y-2">
                <h5 className="font-semibold text-color-orange-500 mb-8">
                  Office
                </h5>
                <div className="flex gap-4">
                  <div className="mt-1">
                    <FaMapMarkerAlt className="text-slate-200" size={20} />
                  </div>
                  <p className="text-base text-slate-200">
                    Andheri Industrial Estate, Veera Desai Road, Andheri West,
                    Mumbai, India
                  </p>
                </div>
                <div className="flex gap-4">
                  <div className="mt-1">
                    <MdMailOutline className="text-slate-200" size={20} />
                  </div>
                  <p className="text-base text-slate-200 self-center">
                    <Link
                      href="mailto:sales1@prateeksha.com"
                      className="text-slate-200 hover:text-sky-400"
                    >
                      sales1@prateeksha.com
                    </Link>
                  </p>
                </div>
                <div className="flex gap-4">
                  <div className="mt-1">
                    <FaPhoneAlt className="text-slate-200" size={20} />
                  </div>
                  <p>
                    <Link
                      href="tel:+919821212676"
                      className="text-slate-200 hover:text-sky-400"
                    >
                      +91 98212 12676
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-span-full lg:col-span-2">
                <h5 className="font-semibold text-color-orange-500 mb-6 md:mb-8">
                  Locations
                </h5>
                <ul className="text-left grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 text-slate-200 text-base">
                  {[
                    {
                      name: "Agentie, Montana",
                      href: "/locations/web-design-services-agentie-montana-united-states",
                    },
                    {
                      name: "Austin, Texas",
                      href: "/locations/website-design-services-austin-texas",
                    },
                    {
                      name: "Berkeley, California",
                      href: "/locations/web-design-services-berkeley-california-united-states",
                    },
                    {
                      name: "Bicester, Oxfordshire",
                      href: "/locations/web-design-services-bicester-oxfordshire",
                    },
                    {
                      name: "Calgary, Alberta",
                      href: "/locations/website-design-services-calgary-alberta-canada",
                    },
                    {
                      name: "Charleston, Sc South Carolina",
                      href: "/locations/web-developer-services-charleston-sc-south-carolina-united-states",
                    },
                    {
                      name: "Cork, Ireland",
                      href: "/locations/web-design-services-cork-ireland",
                    },
                    {
                      name: "El Paso",
                      href: "/locations/website-design-services-el-paso",
                    },
                    {
                      name: "Folsom, California",
                      href: "/locations/web-design-services-folsom-california-united-states",
                    },
                    {
                      name: "Glendale, California",
                      href: "/locations/web-design-services-glendale-california-united-states",
                    },
                    {
                      name: "Joliet, Illinois",
                      href: "/locations/web-design-services-joliet-illinois-united-states",
                    },
                    {
                      name: "Las Vegas",
                      href: "/locations/website-designing-services-las-vegas",
                    },
                    {
                      name: "London",
                      href: "/locations/website-design-services-london",
                    },
                    {
                      name: "New Jersey, USA",
                      href: "/locations/web-design-services-new-jersey-united-states",
                    },
                    {
                      name: "New Jersey, USA",
                      href: "/locations/shopify-website-design-new-jersey-united-states",
                    },
                    {
                      name: "Orange County",
                      href: "/locations/web-developer-services-orange-county-california-united-states",
                    },
                    {
                      name: "Orlando, Florida",
                      href: "/locations/website-design-services-orlando-florida",
                    },
                    {
                      name: "Parramatta, New South Wales, Australia",
                      href: "/locations/website-design-services-parramatta-new-south-wales-australia",
                    },
                    {
                      name: "Rochester, New York NY",
                      href: "/locations/web-development-rochester-ny-new-york-united-states",
                    },
                    {
                      name: "St. Louis",
                      href: "/locations/website-design-services-st-louis",
                    },
                    {
                      name: "St. Louis, Missouri",
                      href: "/locations/custom-web-design-for-st-louis-missouri-united-states",
                    },
                    {
                      name: "Washington DC",
                      href: "/locations/website-design-services-washington-dc",
                    },
                    {
                      name: "Zanzibar",
                      href: "/locations/web-design-services-zanzibar",
                    },
                  ].map((location, index) => (
                    <li key={index}>
                      <Link href={location.href}>{location.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className=" text-white mt-6 flex flex-col items-center justify-center">
                <h5 className="font-semibold text-color-orange-500 mb-3">
                  Follow Us
                </h5>
                <div className="flex mt-3 md:mt-8 space-x-6 text-sky-400">
                  <a
                    href="https://www.linkedin.com/in/prateekshaweb/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin className="w-6 h-6" />
                  </a>
                  <a
                    href="https://www.facebook.com/prateekshaweb/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:opacity-75"
                  >
                    <FaFacebook className="w-6 h-6" />
                  </a>
                  <a
                    href="https://www.instagram.com/prateekshawebdesigners/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:opacity-75"
                  >
                    <FaInstagram className="w-6 h-6" />
                  </a>
                  <a
                    href="https://twitter.com/PrateekshaWeb"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:opacity-75"
                  >
                    <FaTwitter className="w-6 h-6" />
                  </a>
                  <a
                    href="https://github.com/prateekshaweb"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:opacity-75"
                  >
                    <FaGithub className="w-6 h-6" />
                  </a>
                </div>
                <p className="mt-4 text-base">
                  We're eager to discover more about you and collaborate on
                  crafting a successful web development strategy. Reach out to
                  one of our team to start our journey together.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="flex-1 text-slate-200 w-full bg-color-brown-800 py-4 justify-center items-center my-auto place-content-center">
          <div className="max-w-screen-xl mx-5 lg:mx-auto text-sm flex flex-col lg:flex-row items-center lg:items-start justify-center md:justify-between h-full">
            <div className="text-color-brown-200">
              © 2024 · Prateeksha Web Design. All rights reserved
            </div>
            <div>Built with Next.js</div>
            <div>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default React.memo(Footer);
