// app/components/SubscribeForm.tsx
"use client";

import { useState } from "react";
import Image from "next/image";

const SubscribeForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");

    const response = await fetch("/api/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (response.ok) {
      setMessage(data.message);
      setEmail("");
    } else {
      setMessage(data.error);
    }
  };

  return (
    <section>
      <section className="flex-1 bg-color-orange-500">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="flex flex-col md:flex-row items-stretch justify-start h-full ">
            <div className="w-full md:w-3/5 py-8 max-w-screen-md">
              <h3 className="font-bold text-gray-900 mb-4">
                Get Special Offers and Get Latest Updates from our blogs!
              </h3>
              <p className="mt-4">
                Subscribe to our newsletter for exclusive offers and discounts
                on our packages. Receive bi-weekly updates from our blog for the
                latest news and insights.
              </p>
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                  required
                  className="p-3 block2 w-80 mr-4"
                />
                <button
                  type="submit"
                  className="px-8 py-3 text-center bg-red-800 text-white"
                >
                  Subscribe
                </button>
                {message && <p>{message}</p>}
              </form>
            </div>
            <div className="hidden md:block w-2/5 relative">
              <Image
                src="/images/web-technology-new.png"
                fill
                alt="By working together, we can boost the visibility of your brand."
                sizes="100vw"
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default SubscribeForm;
